import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SharedImage } from '../constants/image-constant';
import { MenuWrapper, MenuWrapper2 } from './menu';
import { Between, Row } from './layout';
import { LogoWrapper } from './Logo';
import './FingerHeader.css'
import { Store } from '../store/store-reducer';
import './walletSelector.css'
import React from "react";
import ConnectWallet from './connectWallet';
const {Logo } = SharedImage;


export default function Header() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const { state } = React.useContext(Store);


    return (
        <HeaderWrapper>
            <HeaderContainer>
                <div className='header-container'>
                    <LogoWrapper src={Logo} alt="cronos-fighter" />
                    <MenuWrapper2 >
                        <a href='https://discord.gg/wg8nrSWf' target='_blank' rel="noreferrer">
                            <i class="fab fa-discord" style={{ fontSize: 'calc(1.8vw + 10px)', color: 'white' }}></i>
                        </a>
                        <a href='https://twitter.com/SpecterOfArtNFT' target='_blank' rel="noreferrer">
                            <i class="fab fa-twitter" style={{ fontSize: 'calc(1.8vw + 10px)', color: 'white' }}></i>
                        </a>
                    </MenuWrapper2>
                    <Between />
                    <MenuWrapper>
                    <div className={click ? 'nav-dropdown active' : 'nav-dropdown'}>
                                <Link
                                    to='/FingerMonkeys'
                                    className='header-links'
                                >
                                    Home
                                </Link>
                                <ul>
                                    <li><a style={{ textDecoration: 'none', color: 'white', fontFamily: "Josefin Sans" }} href="/FingerMonkeys#about">Intro</a></li>
                                    <li><a style={{ textDecoration: 'none', color: 'white', fontFamily: "Josefin Sans" }} href="/FingerMonkeys#roadmap">Roadmap</a></li>
                                    <li><a style={{ textDecoration: 'none', color: 'white', fontFamily: "Josefin Sans" }} href="/FingerMonkeys#utility">Utility</a></li>
                                    <li><a style={{ textDecoration: 'none', color: 'white', fontFamily: "Josefin Sans" }} href="/FingerMonkeys#team">Team</a></li>
                                </ul>
                            </div>
                    </MenuWrapper>
                    <div className='wallet-selector-container'>
                        <ConnectWallet />
                    </div>
                </div>
            </HeaderContainer>
        </HeaderWrapper>
    )

}

const HeaderContainer = styled.header`
width:90rem;
padding:20px;
@media screen and (max-width:700px){
    width:100%;
    padding:5px;
}
`

const HeaderWrapper = styled.header`
    color:white;
    background-color:rgba(0,0,0,.15);
    max-height: 10vh;
    min-height: 70px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
`
