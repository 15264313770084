// config/config.ts
declare global {
  interface Window {
    ethereum: any;
  }
}
export const configVars = {
  mode: "regular",
  rpcNetwork: {
    rpcUrl: "https://evm.cronos.org/",
    chainId: 25,
    chainIdHex: "0x19",
    chainName: "Cronos Mainnet Beta",
    chainType: "mainnet",
    nativeCurrency: {
      name: "CRO",
      symbol: "CRO",
      decimals: 18,
    },
    blockExplorerUrl: "https://cronoscan.com/",
  },
  rpcNetwork_mainnet: {
    rpcUrl: "https://evm.cronos.org/",
    chainId: 25,
    chainIdHex: "0x19",
    chainName: "Cronos Mainnet Beta",
    chainType: "mainnet",
    nativeCurrency: {
      name: "CRO",
      symbol: "CRO",
      decimals: 18,
    },
    blockExplorerUrl: "https://cronoscan.com/",
  },
  V1MarketplaceAddress: {
    address: "0x7a3CdB2364f92369a602CAE81167d0679087e6a3", // on Cronos Testnet
    address_mainnet: "0x7a3CdB2364f92369a602CAE81167d0679087e6a3", // This is the ebisusbay marketplace contract
  },
  V1MintContract: {
    address: "0x0e8f883124c1c234d64Ec566adC5966f1832d207" //V1 Fighter Mint Contract
  }
};
