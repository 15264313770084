import styled from "styled-components";
import Hero from "../hero";
import Footer from "../footer";
import { SharedImage, NftImage } from "../../constants/image-constant";
import { Container } from "../container";
import { Between, BodyText, Heading1, Heading2, Row } from "../layout";
import { Uppercase } from "../../utillities/uppercase";
import { settings, settings2 } from "../../configs/app-config";
import "./FingerMonkeys.css";
import { MenuWrapper, MenuWrapper2 } from "../menu";
import {
  RoadmapContent,
  RoadmapTimeline,
  RoadmapTimelineLeft,
  RoadmapTimelineRight,
} from "../roadmap";
import Slider from "react-slick";
import Header from "../FingerHeader";

const {
  Monkey1,
  Monkey2,
  Monkey3,
  Monkey4,
  Monkey5,
  Monkey6,
  Monkey7,
  Monkey8,
  Monkey9,
  Monkey10,
  Monkey11,
  Monkey12,
  Monkey13,
  Monkey14,
  Monkey15,
  Monkey16,
  Sam,
  Shahab,
  Artist,
  Map,
} = NftImage;

const { Monkey } = SharedImage;

export default function FingerMonkeys() {
  return (
    <>
      <Header />

      <Content>
        <Hero />
        <Container
          style={{ marginTop: "100px", marginBottom: "100px" }}
          id="about"
        >
          <Row style={{ justifyContent: "center" }}>
            <ImageWrapper className="wow fadeInLeft">
              <img
                src={Monkey10}
                alt="cronos-nft"
                style={{ borderRadius: "10px" }}
              />
            </ImageWrapper>
            <Between />
            <TextWrapper>
              <Heading1 className="wow fadeInUp">
                {Uppercase("What Are The Finger Monkeys?")}
              </Heading1>
              <BodyText className="wow fadeInUp" style={{ marginTop: "30px" }}>
                The Finger Monkeys NFT Collection features 5555 animated NFT’s
                created by a group of talented individuals led by Art Yan. The
                idea of Finger Monkeys NFT was created by Art because of his
                love and passion towards animals. Since his obsession to adopt a
                Finger Monkey was huge, he decided to illustrate his dream into
                the Finger Monkeys NFT. The Finger Monkeys NFT became the first
                collection for SpecterOfArt. Their work is so iconic that it is
                hard to resist not to be a part of their collection family.
                Every wallet needs a LUCKY Finger Monkey in the collection.
                Finger Monkey collection some of the animations look cool, some
                look funny and some of them even look weird. It all depends on
                what the collector is interested in. Their main goal is to
                inspire collectors with their colorful, happy and artsy
                collectives. The Specter of Art world will be a fascinating and
                illustrative one with the Finger Monkeys being the first of the
                collection.
              </BodyText>
            </TextWrapper>
          </Row>
        </Container>
        <Row>
          <CarouselWrapper>
            <Slider {...settings2}>
              <div>
                <img
                  src={Monkey8}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey9}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey1}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey2}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey3}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey4}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey5}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
            </Slider>
          </CarouselWrapper>
        </Row>
        <Container id="roadmap">
          <Heading1 className="wow fadeInUp">{Uppercase("Roadmap")}</Heading1>
          <RoadmapTimeline>
            <RoadmapTimelineLeft className="wow bounceInLeft">
              <RoadmapContent>
                <Heading2>{Uppercase("Phase One - Launch")}</Heading2>
                <BodyText>
                  September 29 Launch on Ebisus Bay
                  <br />
                  We are super excited to share our new project to the NFT
                  World.
                  <br />
                  Our first NFT collection is our cool and weird Finger Monkeys.
                  <br />
                  Rarities will get % of royalties - holders of 10 or more will
                  get entries to Wanted Wednesdays!
                  <br />
                </BodyText>
              </RoadmapContent>
            </RoadmapTimelineLeft>
            <RoadmapTimelineRight className="wow bounceInRight">
              <RoadmapContent>
                <Heading2>{Uppercase("Phase 2 - Mint-Out")}</Heading2>
                <BodyText>
                  After Mint, Specter of Art will create a creative Lore
                  platform for holders to input data and populate the feed.
                  <br />
                  Wanted Wednesdays begins!
                  <br />
                  Rarity ranks released 5 days after Mint
                  <br />
                  Female Finger Monkeys to be released, holding both will give
                  you a free Baby Monkey airdrop!!
                  <br />
                  You can find our collection on Ebisusbay.com
                  <br />
                </BodyText>
              </RoadmapContent>
            </RoadmapTimelineRight>
            <RoadmapTimelineLeft className="wow bounceInLeft">
              <RoadmapContent>
                <Heading2>{Uppercase("Phase 3 - After Mint")}</Heading2>
                <BodyText>
                  Specter of Art will release an interactive map showing
                  ecosystem, token, staking, lore, animated series and more!
                  <br />
                  <img
                    src={Map}
                    style={{ borderRadius: "10px", marginTop: "10px" }}
                  ></img>
                </BodyText>
              </RoadmapContent>
            </RoadmapTimelineLeft>
          </RoadmapTimeline>
        </Container>
        <Row style={{ marginTop: "100px" }}>
          <CarouselWrapper>
            <Slider {...settings2}>
              <div>
                <img
                  src={Monkey7}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey6}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey1}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey2}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey3}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey4}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
              <div>
                <img
                  src={Monkey5}
                  alt="cronos-Monkey"
                  styles={{ border: "2px solid white", borderRadius: "15px" }}
                  className="carousel-picture"
                />
              </div>
            </Slider>
          </CarouselWrapper>
        </Row>
        <Row
          id="utility"
          style={{
            padding: "80px 20px",
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Heading1
            className="wow fadeInUp"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            {Uppercase("Utility")}
            <TextWrapper
              style={{
                maxWidth: "800px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <BodyText></BodyText>
            </TextWrapper>
          </Heading1>

          <Row>
            <UtilityWrapper>
              <li>
                <UtilityCard className="wow bounceInLeft">
                  <Heading2 style={{ textAlign: "center" }}>
                    {Uppercase("Royalties")}
                  </Heading2>
                  <BodyText>Top 1000 rank gets % of royalties</BodyText>
                </UtilityCard>
              </li>
              <li>
                <UtilityCard className="wow bounceInLeft">
                  <Heading2 style={{ textAlign: "center" }}>
                    {Uppercase("Bounties")}
                  </Heading2>
                  <BodyText>
                    $50 in CRO a week “Wanted Wednesdays” giveaway for holders
                    of 10 or more Finger Monkeys.
                  </BodyText>
                </UtilityCard>
              </li>
              <li>
                <UtilityCard className="wow bounceInLeft">
                  <Heading2 style={{ textAlign: "center" }}>
                    {Uppercase("Holders")}
                  </Heading2>
                  <BodyText>
                    Holders of 25 or more Finger Monkeys will get a monthly % of
                    royalties of all NFTs coming from project.{" "}
                  </BodyText>
                </UtilityCard>
              </li>
            </UtilityWrapper>
          </Row>
        </Row>
        <Row
          id="teams-cronos"
          style={{
            justifyContent: "center",
          }}
        >
          <Row
            style={{
              justifyContent: "center",
            }}
          >
            <Heading1 className="wow fadeInUp">
              {Uppercase("The Team")}
            </Heading1>
          </Row>
          <Row
            id="team"
            style={{
              justifyContent: "space-between",
              maxWidth: "1440px",
            }}
          >
            <Card>
              <img
                src={Sam}
                style={{ width: "300px", height: "auto", borderRadius: "10px" }}
                className="wow bounceInUp"
                alt="cronos-Monkey"
              />
              <h2 className="team-description">Lead Blockchain Developer</h2>
              <h3 className="team-section-name">Samuel Ouzounian</h3>
              <MenuWrapper2 id="social-menu">
                <a
                  href="https://www.instagram.com/samouzounian/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    class="fab fa-instagram"
                    style={{ fontSize: "calc(1.8vw + 10px)", color: "white" }}
                  ></i>
                </a>
                <a
                  href="https://twitter.com/0xProbatorem"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    class="fab fa-twitter"
                    style={{ fontSize: "calc(1.8vw + 10px)", color: "white" }}
                  ></i>
                </a>
              </MenuWrapper2>
            </Card>
            <Card>
              <img
                src={Artist}
                style={{ width: "300px", height: "auto", borderRadius: "10px" }}
                className="wow bounceInUp"
                alt="cronos-Monkey"
              />
              <h2 className="team-description">Founder / Artist</h2>
              <h3 className="team-section-name">Art Yan</h3>
              <MenuWrapper2 id="social-menu">
                <a
                  href="https://twitter.com/artyanNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    class="fab fa-twitter"
                    style={{ fontSize: "calc(1.8vw + 10px)", color: "white" }}
                  ></i>
                </a>
              </MenuWrapper2>
            </Card>
          </Row>
        </Row>
      </Content>
      <Footer />
    </>
  );
}

const Content = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  #about-cronos {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  #roadmap-cronos {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  #utility-cronos {
    margin-top: 180px;
  }

  #teams-cronos {
    padding-bottom: 180px;
    padding-top: 180px;
    margin-bottom: 180px;
    z-index: 10;
    background-color: #131313;
  }
  #teams-wrapper {
    margin-top: 50px;
    gap: 30px;
  }
`;

const ImageWrapper = styled.div`
  max-width: 30%;
  height: auto;
  text-align: center;
  @media screen and (max-width: 1220px) {
    order: 1;
  }
`;

const TextWrapper = styled.div`
  max-width: 620px;
  @media screen and (max-width: 1220px) {
    order: 2;
  }
`;

const CarouselWrapper = styled.div`
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
  max-width: 660px;
  padding: 20px;
  border-radius: 10px;
`;
const UtilityCard = styled.div`
  overflow-wrap: normal;
  display: flex;
  flex-direction: column;
  background-color: #0c0b0be0;
  width: 400px;
  padding: 20px;
  height: 350px;
  text-align: center;
  border-radius: 10px;
  @media screen and (max-width: 400px) {
    width: 200px;
  }
`;

const UtilityWrapper = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0px;
  gap: 30px;
  @media screen and (max-width: 1450px) {
    height: auto;
    justify-content: center;
    align-items: center;
  }
`;
