import { Container } from "./container";
import styled from "styled-components";
import { Uppercase } from "../utillities/uppercase";
import { HeadingSupper } from "./layout";
import "./hero.css";

export default function Hero() {
  return (
    <HeroWrapper>
      <HeadingSupper className="wow zoomInUp">
        {Uppercase("Finger Monkeys")}
      </HeadingSupper>
      <a
        href="https://app.ebisusbay.com/drops/finger-monkeys#drop_detail"
        target="_blank"
      >
        <button className="mint-button">MINT</button>
      </a>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 90vh;
  background-image: url(./image/jungle.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
`;
