import Logo from '../assets/image/finger-monkeys-logo.png';
import DiscordIco from '../assets/image/discord.png';
import TwitterIco from '../assets/image/twitter.png';
import InstagramIco from '../assets/image/instagram.png';
import Monkey1 from '../assets/image/33.png'
import Monkey2 from '../assets/image/34.png'
import Monkey3 from '../assets/image/35.png'
import Monkey4 from '../assets/image/36.png'
import Monkey5 from '../assets/image/37.png'
import Monkey6 from '../assets/image/38.png'
import Monkey7 from '../assets/image/39.png'
import Monkey8 from '../assets/image/43.png'
import Monkey9 from '../assets/image/44.png'
import Monkey10 from '../assets/image/45.png'
import Monkey11 from '../assets/image/46.png'
import Monkey12 from '../assets/image/47.png'
import Monkey13 from '../assets/image/48.png'
import Monkey14 from '../assets/image/31.png'
import Monkey15 from '../assets/image/49.png'
import Monkey16 from '../assets/image/50.png'
import JollyJaguar from '../assets/image/jollyJaguar.png'
import metamask from '../assets/image/metamask-fox.svg'
import walletConnectImage from '../assets/image/WalletConnect.svg'
import Crypto from '../assets/image/cdc.png'
import Sam from '../assets/image/sam.png';
import Shahab from '../assets/image/shahabs.jpg';
import Artist from '../assets/image/artist.jpg';
import Map from '../assets/image/map.png';


export const SharedImage = {
    Logo:Logo,
    DiscordIco:DiscordIco,
    TwitterIco:TwitterIco,
    InstagramIco:InstagramIco
}

export const NftImage = {
    Crypto:Crypto,
    metamask:metamask,
    walletConnectImage:walletConnectImage,
    Monkey1:Monkey1,
    Monkey2:Monkey2,
    Monkey3:Monkey3,
    Monkey4:Monkey4,
    Monkey5:Monkey5,
    Monkey6:Monkey6,
    Monkey7:Monkey7,
    Monkey8:Monkey8,
    Monkey9:Monkey9,
    Monkey10:Monkey10,
    Monkey11:Monkey11,
    Monkey12:Monkey12,
    Monkey13:Monkey13,
    Monkey14:Monkey14,
    Monkey15:Monkey15,
    Monkey16:Monkey16,
    JollyJaguar:JollyJaguar,
    Sam:Sam,
    Shahab:Shahab,
    Artist:Artist,
    Map:Map,

}
