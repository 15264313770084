import styled from 'styled-components';

export const LogoWrapper = styled.img`
    max-width:25vh;
    min-width:150px;
    height:auto;
    border-radius:9999999px;
    @media screen and (max-width:700px){
        max-width:25vw;
        min-width:0px;
    }
    @media screen and (max-width:400px){
        max-width:30vw;
        min-width:0px;
    }
`








