import { Container } from "./container";
import styled from 'styled-components';
import { Uppercase } from "../utillities/uppercase";
import { HeadingSupper } from "./layout";
import { SharedImage, NftImage } from "../constants/image-constant";
import './HHero.css'
const {
    Monkey1,

} = NftImage;

export default function Hero() {
    return (
        <HeroWrapper>
            <h1 className="specter-header">
                {Uppercase("Specter Of Art")}
            </h1>
            <div className="specter-container">
                <img className="specter-artist" src='../image/artist.jpg'></img>
                <div className="specter-artist-info-container">
                    <h1 className="artist-name">
                        {Uppercase("Art Yan")}
                    </h1>
                    <h3 className="artist-details">Art Yan is a father of three amazing kids who is passionate about technology, art and animals. He has decided to combine all of them to express his passion through his NFT artwork.Being the leader of the project, his goal is to facilitate people’s life and to give back to his communities. Art Yan was born in Armenia, raised in Spain and currently living in Los Angeles. Living in 3 different cultures his point of view has changed and so he decided to create something different for the community. Because of his hard-working personality and entrepreneurial mindset, The Finger Monkeys project was created to help people by creating Web3 and Crypto classes in these three countries so that they can have better understanding of the new world technology and digital assets. Art aims to build in the Cronos chain a sustainable project that aims to serve other projects and builders. Specter of Art will be a platform used to help other projects grow and fulfill their needs.</h3>
                </div>
            </div>

        </HeroWrapper>
    )
}

const HeroWrapper = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    color:white;
    min-height:100vh;
    background-image:url(./image/background.webp);
    background-size:cover;
    background-position:center;
    width:100%;
`