import styled from 'styled-components';

export const MenuWrapper = styled.ul`
    display:flex;
    flex-direction:row;
`
export const MenuWrapper2 = styled.ul`
    margin-left:20px;
    display:flex;
    flex-direction:row;
    list-style:none;
    gap:30px;
    padding:0px;
    @media screen and (max-width:700px){
        margin-left:5px;
        gap:15px;
    }
`