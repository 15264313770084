import styled from "styled-components";
import Hero from "../HHero";
import Footer from "../footer";
import { SharedImage, NftImage } from "../../constants/image-constant";
import { Container } from "../container";
import { Between, BodyText, Heading1, Heading2, Row } from "../layout";
import { Uppercase } from "../../utillities/uppercase";
import { settings, settings2 } from "../../configs/app-config";
import "./Home.css";
import { MenuWrapper, MenuWrapper2 } from "../menu";
import {
  RoadmapContent,
  RoadmapTimeline,
  RoadmapTimelineLeft,
  RoadmapTimelineRight,
} from "../roadmap";
import Slider from "react-slick";

const {
  Monkey1,
  Monkey2,
  Monkey3,
  Monkey4,
  Monkey5,
  Monkey6,
  Monkey7,
  Monkey8,
  Monkey9,
  Monkey10,
  Monkey11,
  Monkey12,
  Monkey13,
  Monkey14,
  Monkey15,
  Monkey16,
  JollyJaguar,
} = NftImage;

const { Monkey } = SharedImage;

export default function Home() {
  return (
    <>
      <Content>
        <Hero />
        <div
          style={{
            minHeight: "100vh",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <Row
            style={{
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <h1 className="collection-header">COLLECTIONS</h1>
          </Row>
          <Row
            id="collections"
            style={{
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <Card>
              <a
                href="/FingerMonkeys"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Monkey14}
                  style={{
                    width: "300px",
                    height: "auto",
                    borderRadius: "10px",
                  }}
                  className="wow bounceInUp"
                  alt="cronos-Monkey"
                />
                <h2 className="collection-name">FINGER MONKEYS</h2>
                <h3
                  style={{
                    fontSize: "25px",
                    color: "white",
                    textAlign: "center",
                    lineHeight: "55px",
                    fontFamily: "'Yanone Kaffeesatz', sans-serif",
                  }}
                >
                  MINT: 9/29
                </h3>
              </a>
            </Card>
            <Card>
              <img
                src={JollyJaguar}
                style={{ width: "300px", height: "auto", borderRadius: "10px" }}
                className="wow bounceInUp"
                alt="cronos-Monkey"
              />
              <h2 className="collection-name">JOLLY JAGUARS</h2>
              <h3
                style={{
                  fontSize: "25px",
                  color: "white",
                  fontFamily: "'Yanone Kaffeesatz', sans-serif",
                }}
              >
                (COMING SOON)
              </h3>
            </Card>
          </Row>
        </div>
      </Content>
    </>
  );
}

const Content = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  #about-cronos {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  #roadmap-cronos {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  #utility-cronos {
    margin-top: 180px;
  }

  #teams-cronos {
    padding-bottom: 180px;
    padding-top: 180px;
    margin-bottom: 180px;
    z-index: 10;
    background-color: #131313;
  }
  #teams-wrapper {
    margin-top: 50px;
    gap: 30px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
  max-width: 660px;
  height: 550px;
  padding: 20px;
  margin-right: 30px;
  margin-left: 30px;
  border-radius: 10px;
  @media screen and (max-width: 1200px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;
