import styled from 'styled-components';

export const Row = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    @media screen and (max-width:1220px){
        :not(#header-row){
            flex-direction:column;
            align-items:center;

        }
    }
`

export const Between = styled.div`
    flex:1;
`

export const HeadingSupper = styled.h1`
font-family: 'Amatic SC', cursive;
    font-size:9rem;
    color:white;
    text-align:center;
    @media screen and (max-width:1024px){
        font-size:7rem;
        line-height:8rem;
    }
    @media screen and (max-width:600px){
        font-size:5rem;
        line-height:6rem;
    }
    @media screen and (max-width:480px){
        font-size:4em;
        line-height:5rem;
    }
`

export const Heading1 = styled.h1`
font-family: 'Amatic SC', cursive;
    font-size:4rem;
    line-height:52px;
    color:white;
    text-align:center;
    @media screen and (max-width:600px){
        font-size:2.5rem;
        line-height:3.5rem;
    }
    @media screen and (max-width:480px){
        font-size:2em;
        line-height:3rem;
    }
`

export const Heading2 = styled.h2`
font-family: 'Amatic SC', cursive;
    font-size:2.5rem;
    line-height:3rem;
    color:white;
    text-align:left;

`

export const BodyText = styled.p`
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 32px;
/* or 160% */


/* Strong grey */

color: #7E7F81;
`