import * as React from 'react'
import { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import FingerMonkeys from './components/pages/FingerMonkeys';
import WOW from "wowjs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import Home from './components/pages/Home';

function App() {
  useEffect(() => {
    new WOW.WOW().init();

  }, []);
  return (
    <div>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/FingerMonkeys" element={<FingerMonkeys />} />
      </Routes>
    </div>
  );
}

export default App;
